import { H2 } from './Heading'
export const Youtube1 = ({urls, height}: {urls: any, height: string}) => {
  return (
    <div className="grid gap-2 sm:grid-cols-1">
      {urls.map((url: any, key: any) => (
        <div key={key}>
          <iframe
            src={`https://www.youtube.com/embed/${url.id}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            width="100%"
            height={height}
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  )
}

export const Youtube2 = ({urls, height}: {urls: any, height: string}) => {
  return (
    <>
    <div className="grid gap-2 sm:grid-cols-2">
      {urls.map((url: any, key: any) => (
        <div key={key}>
          {url.title == null ? (
            null
          ) : (
            <H2>{url.title}</H2>
          )}
          <iframe
            src={`https://www.youtube.com/embed/${url.id}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            width="100%"
            height={height}
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
    </>
  )
}
