import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { RiCloseFill } from 'react-icons/ri'
import { AiOutlineMenu } from 'react-icons/ai'
import { AiFillMail } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import Logo from '../../img/logo.jpeg'
export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="container mx-auto">
      <div className="lg:w-11/12 mx-auto">
        <div className="lg:w-5/6 mx-auto">

          <div className="sticky top-0 z-50 px-4 py-4 w-full bg-white">
            <div className="flex items-center justify-between">

              <img src={Logo} width="30%" alt="アイコン" className="cursor" onClick={() => navigate(`/`)} />

              <ul className="flex items-center hidden space-x-4 lg:flex">
                <li >
                  <div className="text-gray-700 text-sm cursor">
                    <a href={`https://e-funtime.com/`} target="_blank" rel="noopener noreferrer">English Fun Time</a>
                  </div>
                </li>
                <li >
                  <div className="text-gray-700 text-sm cursor" onClick={() => navigate(`/programming`)}>
                    プログラミング
                  </div>
                </li>
                <li >
                  <div className="text-gray-700 text-sm cursor" onClick={() => navigate(`/sokudoku`)}>
                    速読解・速読聴
                  </div>
                </li>
                <li >
                  <div className="text-gray-700 text-sm cursor" onClick={() => navigate(`/digi-soro`)}>
                    デジそろ
                  </div>
                </li>
                <li >
                  <div className="text-gray-700 text-sm cursor" onClick={() => navigate(`/progra-dojo`)}>
                    プロクラ道場
                  </div>
                </li>
                <li >
                  <div className="text-gray-700 text-sm cursor" onClick={() => navigate(`/blog`)}>
                    ブログ
                  </div>
                </li>
              </ul>

              <ul className="flex items-center space-x-8 lg:flex">
                <li>
                  <a className="inline-flex items-center rounded bg-red-700 px-3 py-3 text-white cursor" onClick={() => navigate(`/trial-contact`)}>
                    <AiFillMail />
                    <span className="text-sm font-bold ml-1"> 体験会申し込み </span>
                  </a>
                </li>
              </ul>

              <div className="lg:hidden">

                <button className="p-2" onClick={() => setIsMenuOpen(true)} >
                  <div className="flex flex-col w-10 h-10 justify-center items-center">
                    <IconContext.Provider value={{ color: 'gray', size: '100%' }}>
                      <AiOutlineMenu />
                      <div className="text-gray-500 text-[10px]">
                        メニュー
                      </div>
                    </IconContext.Provider>
                  </div>
                </button>

                {isMenuOpen && (
                  <div className="absolute top-0 left-0 w-full">
                    <div className="p-5 bg-white border rounded shadow-sm">
                      <div className="flex items-center justify-between mb-4">
                        <button className="p-2 -mt-2 -mr-2" onClick={() => setIsMenuOpen(false)} >
                          <RiCloseFill />
                        </button>
                      </div>
                      <nav>
                        <ul className="space-y-4">
                          <li >
                            <div className="text-gray-700" onClick={() => navigate(`/`)}>
                              ホーム
                            </div>
                          </li>
                          <li >
                            <div className="text-gray-700">
                              <a href={`https://e-funtime.com/`} target="_blank" rel="noopener noreferrer">English Fun Time</a>
                            </div>
                          </li>
                          <li >
                            <div className="text-gray-700" onClick={() => navigate(`/programming`)}>
                              プログラミング
                            </div>
                          </li>
                          <li >
                            <div className="text-gray-700" onClick={() => navigate(`/sokudoku`)}>
                              速読解・速読聴
                            </div>
                          </li>
                          <li >
                            <div className="text-gray-700" onClick={() => navigate(`/digi-soro`)}>
                              デジそろ
                            </div>
                          </li>
                          <li >
                            <div className="text-gray-700" onClick={() => navigate(`/progra-dojo`)}>
                              プロクラ道場
                            </div>
                          </li>
                          <li >
                            <div className="text-gray-700" onClick={() => navigate(`/blog`)}>
                              ブログ
                            </div>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
