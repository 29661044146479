import { useNavigate } from "react-router-dom";
export default function Banner({ datas }: { datas: any }) {
  const navigate = useNavigate();
  return (
    <>
      {datas.map((data: any) => {
        if (data.url != null) {
          return <img src={data.src} className="w-full m-auto mb-2 cursor" alt="" onClick={() => navigate(data.url)} />
        } else if (data.href != null) {
          return <a href={data.href} target="_blank"><img src={data.src} className="w-full m-auto mb-2 cursor" alt="" /></a>
        } else {
          return <img src={data.src} className="w-full m-auto mb-2 cursor" alt="" />
        }
      })}
    </>
  )
}
