import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { ScrollTop } from './utils/ScrollTop'
import { CheckboxMultiple } from './elements/Form'
import { Radiobutton } from './elements/Form'
import { Textfield } from './elements/Form'
import { Textarea } from './elements/Form'
import { ZipcodeForm } from './elements/Form'
import { Checkbox } from './elements/Form'
import Img1 from '../img/trial-contact/1.jpeg'
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { init, send } from 'emailjs-com';

import axios from "axios";
import { ChangeEvent, useState } from "react";

type Zipcode = {
  main: string;
  sub: string;
};
type Address = {
  address1: string;
  address2: string;
};

export default function TrialContact() {

  const [flag, setFlag] = useState(false)
  const [text, setText] = useState('送信')

  const [zipcode, setZipcodeMain] = useState<Zipcode>({
    main: "",
    sub: ""
  });

  const [address, setAddress] = useState<Address>({
    address1: "",
    address2: ""
  });

  const updateZipcodeAddress1 = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress({
      ...address,
      address1: e.target.value
    });
  };

  const updateZipcodeAddress2 = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress({
      ...address,
      address2: e.target.value
    });
  };

  const updateZipcodeMain = (e: ChangeEvent<HTMLInputElement>) => {
    setZipcodeMain({ ...zipcode, main: e.target.value });
  };

  const updateZipcodeSub = async (e: ChangeEvent<HTMLInputElement>) => {
    setZipcodeMain({ ...zipcode, sub: e.target.value });
    if (e.target.value.length === 4 && zipcode.main.length === 3) {
      try {
        const res = await axios.get(
          "https://zipcloud.ibsnet.co.jp/api/search",
          {
            params: {
              zipcode: zipcode.main + e.target.value
            }
          }
        );
        if (res.data.results) {
          const result = res.data.results[0];
          setAddress({
            ...address,
            address1: result["address1"],
            address2: result["address2"] + result["address3"]
          })
          setValue("address1", result["address1"])
          setValue("address2", result["address2"] + result["address3"])
        }
      } catch {
        alert("住所の取得に失敗しました。");
      }
    }
  };

  function encode(key: any, val: any) {
    if (key == 'contanct') {
      if (val == 1) {
        return '体験会申し込み'
      } else if (val == 2) {
        return 'お問い合わせ'
      } else if (val == 3) {
        return '取材依頼'
      } else if (val == 4) {
        return 'その他'
      } else {
        return val
      }
    } else if (key == 'confirmation') {
      if (val == 1) {
        return '【有料】プログラ道場(夏のイベント)'
      } else if (val == 2) {
        return '【有料】恐竜をつくろう(夏のイベント)'
      } else if (val == 3) {
        return '【有料】恐竜を動かそう(夏のイベント)'
      } else if (val == 4) {
        return '【無料】プログラ道場'
      } else if (val == 5) {
        return '【無料】自考力キッズ（60分）'
      } else if (val == 6) {
        return '【無料】エジソンアカデミー（60分）'
      } else if (val == 7) {
        return '【無料】飛び級教室・個別保護者説明会（60分）'
      } else if (val == 8) {
        return '【無料】デジそろ（30分）'
      } else if (val == 9) {
        return '【無料】速読解力講座（45分）'
      } else if (val == 10) {
        return '【無料】思考力講座（45分）'
      } else if (val == 11) {
        return '【無料】速読聴英語講座（45分）'
      } else if (val == 12) {
        return '【無料】社会人速読講座（45分）'
      } else if (val == 13) {
        return '【無料】わくわく英語講座'
      } else if (val == 14) {
        return 'English Fun Time'
      } else if (val == 15) {
        return '小学校準備講座'
      }else {
        return val
      }
    } else {
      return val
    }
  }

  function sendJS(data: any) {
    if (data.agreement == false || data.agreement.length == 0) {
      alert('個人情報の取扱いについてへ同意してください。')
    } else if (data.contanct == false || data.agreement.contanct == 0) {
      alert('お問い合わせを選択してください。')
    } else if (data.confirmation == null) {
      alert('体験会申し込みを選択してください。')
    } else if (data.preferred_1_date == '') {
      alert('第１希望日時を入力してください')
    } else if (data.preferred_1_time == '') {
      alert('第１希望日時を入力してください')
    } else if (data.preferred_2_date == '') {
      alert('第２希望日時を入力してください')
    } else if (data.preferred_2_time == '') {
      alert('第２希望日時を入力してください')
    } else if (data.preferred_3_date == '') {
      alert('第３希望日時を入力してください')
    } else if (data.preferred_3_time == '') {
      alert('第３希望日時を入力してください')
    } else if (data.name_1 == '') {
      alert('参加者名1（漢字）を入力してください。')
    } else if (data.kana_1 == '') {
      alert('参加者名1（ふりがな）を選択してください。')
    } else if (data.age_1 == '') {
      alert('参加者名1（年齢）を選択してください。')
    } else if (data.guardian == '') {
      alert('保護者名を選択してください。')
    } else if (data.email == '') {
      alert('メールアドレスを選択してください。')
    } else if (data.phone == '') {
      alert('連絡先を選択してください。')
    } else if (data.zipcode_main == '' || data.zipcode_sub == '') {
      alert('郵便番号を選択してください。')
    } else if (data.address1 == '' || data.address2 == '' || data.address3 == '') {
      alert('住所を選択してください。')
    } else {
      setFlag(true)
      setText('しばらくお待ちください...')
      for (let i = 0; i < data.contanct.length; i++) data.contanct[i] = encode('contanct', data.contanct[i])
      data.confirmation = encode('confirmation', data.confirmation)
      const publicKey = process.env.REACT_APP_PUBLIC_KEY;
      const serviceID = process.env.REACT_APP_SERVICE_ID;
      const templateID = process.env.REACT_APP_TEMPLATE_ID;
      if (publicKey !== undefined && serviceID !== undefined && templateID !== undefined) {
        init(publicKey);
        send(serviceID, templateID, data).then((result) => {
          setText('送信完了')
          alert('お問い合わせありがとうございました。ご入力いただいたメールアドレスに確認メールをお送りしました。')
        }, (error) => {
          setText('送信失敗')
          alert('お問い合わせの送信に失敗しました。お手数ですが、info@terra-cco.jp までご連絡ください。')
        });
      }
    }
  }

  const { register, handleSubmit, setValue } = useForm()

  const contact = async (data: any) => {
    sendJS(data)
  }

  const location = useLocation();

  ScrollTop(location)

  return (
    <>
      <Header />
      <Frame>
        <div className="flex flex-wrap">
          <div className="w-screen">
            <div className="divide-y">
              <div>
                <form onSubmit={handleSubmit(contact)} className="max-w-xl m-auto bg-white bg-opacity-25 rounded border divide-y">

                  <img src={Img1} className=" w-full object-cover object-center" alt="" />

                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          お問い合わせ（複数選択可）
                          <span className="text-red-500 ml-2">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <CheckboxMultiple register={register('contanct')} />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          体験会申し込み
                          <span className="text-red-500 ml-2">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Radiobutton 
                        register={register('confirmation')}
                        preferred_1_date={register('preferred_1_date')}
                        preferred_1_time={register('preferred_1_time')}
                        preferred_2_date={register('preferred_2_date')}
                        preferred_2_time={register('preferred_2_time')}
                        preferred_3_date={register('preferred_3_date')}
                        preferred_3_time={register('preferred_3_time')}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="text-sm text-blue-500">
                      新型コロナウィルスの感染リスクを抑えるため、体験コースへの参加は少人数で対応させていただいております。
                      体験希望日の重複を避けるため希望日・希望時間は３つお願いします。
                      体験日時の確定はメールでお知らせします。
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          参加者名1（漢字）
                          <span className="text-red-500 ml-2">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('name_1')} placeholder={null} />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          参加者名1（かな）
                          <span className="text-red-500">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('kana_1')} placeholder={null} />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          参加者名1（年齢）
                          <span className="text-red-500">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('age_1')} placeholder={null} />
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          参加者名2（漢字）
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('name_2')} placeholder={null} />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          参加者名2（ふりがな）
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('kana_2')} placeholder={null} />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          参加者名2（年齢）
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('age_2')} placeholder={null} />
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          保護者名
                          <span className="text-red-500 ml-2">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('guardian')} placeholder={null} />
                      <span className="text-xs text-blue-500">参加者が18歳未満の方は、必ず保護者名をご入力下さい</span>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          メールアドレス
                          <span className="text-red-500 ml-2">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('email')} placeholder={null} />
                      <span className="text-xs text-blue-500">18歳未満の方は、保護者のメールアドレスをご記入下さい</span>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          連絡先
                          <span className="text-red-500 ml-2">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textfield register={register('phone')} placeholder={null} />
                      <span className="text-xs text-blue-500">18歳未満の方は、保護者の電話番号をご記入下さい</span>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          住所
                          <span className="text-red-500 ml-2">必須</span>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">

                      <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                          郵便番号
                        </div>
                        <div className="sm:w-4/5">
                          <div className="grid grid-cols-1 gap-4 text-center grid-cols-2">
                            <div>
                              <ZipcodeForm register={register('zipcode_main')} value={zipcode.main} placeholder={''} onChange={updateZipcodeMain} />
                            </div>
                            <div>
                              <ZipcodeForm register={register('zipcode_sub')} value={zipcode.sub} placeholder={null} onChange={updateZipcodeSub} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                          都道府県
                        </div>
                        <div className="sm:w-4/5">
                          <ZipcodeForm register={register('address1')} value={address.address1} placeholder={''} onChange={updateZipcodeAddress1} />
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                          市区町村
                        </div>
                        <div className="sm:w-4/5">
                          <ZipcodeForm register={register('address2')} value={address.address2} placeholder={''} onChange={updateZipcodeAddress2} />
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                          町名番地等
                        </div>
                        <div className="sm:w-4/5">
                          <Textfield register={register('address3')} placeholder={''} />
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row p-2">
                        <div className="sm:w-1/5 text-xs mt-4 text-left pb-2">
                          建物名
                        </div>
                        <div className="sm:w-4/5">
                          <Textfield register={register('building')} placeholder={''} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="sm:w-1/3">
                      <ul>
                        <li className="m-3 text-sm">
                          ご質問等
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-2/3 sm:pl-6 sm:border-l border-gray-200 sm:mt-0 text-center sm:text-left">
                      <Textarea register={register('questions')} />
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row p-2">
                    <div className="text-sm font-bold text-gray-500">
                      送信するには個人情報の取扱いについてへの同意が必要です。
                      <span className="text-red-500 text-sm">必須</span>
                      <Checkbox register={register('agreement')} />
                    </div>
                  </div>

                  <button type="submit" disabled={flag} className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2" >
                    {text}
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </Frame>
      <Footer />
    </>
  )
}
