import Arr2str from '../utils/Arr2str'
import { useNavigate } from "react-router-dom";
const style = [
  'w-4',
  'h-4',
  'text-blue-600',
  'bg-gray-100',
  'border-gray-300',
  'focus:ring-blue-500',
  'dark:focus:ring-blue-600',
  'dark:ring-offset-gray-800',
  'focus:ring-2',
  'dark:bg-gray-700',
  'dark:border-gray-600'
]

export const Checkbox = ({ register }: { register: any }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex">
        <div className="flex-col">
          <div className="flex mr-4 mt-2">
            <input {...register} type="checkbox" value="1" className={Arr2str(style)} />
            <label className="ml-2 text-sm font-medium">
              同意する
              <span>
                <a onClick={() => navigate(`/privacy`)} className="ml-3 text-blue-500 underline">個人情報の取扱いについて</a>
              </span>
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export const CheckboxMultiple = ({ register }: { register: any }) => {
  return (
    <>
      <div className="flex mt-3">
        <div className="flex-col">
          <div className="flex mr-4 mb-2">
            <input {...register} type="checkbox" value="1" className={Arr2str(style)} />
            <label className="ml-2 text-sm font-medium">
              体験会申し込み
            </label>
          </div>
          <div className="flex mr-4 mb-2">
            <input {...register} type="checkbox" value="2" className={Arr2str(style)} />
            <label className="ml-2 text-sm font-medium">
              お問い合わせ
            </label>
          </div>
          <div className="flex mr-4 mb-2">
            <input {...register} type="checkbox" value="3" className={Arr2str(style)} />
            <label className="ml-2 text-sm font-medium">
              取材依頼
            </label>
          </div>
          <div className="flex mr-4">
            <input {...register} type="checkbox" value="4" className={Arr2str(style)} />
            <label className="ml-2 text-sm font-medium">
              その他
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export const Radiobutton = ({ 
  register,
  preferred_1_date,
  preferred_1_time,
  preferred_2_date,
  preferred_2_time,
  preferred_3_date,
  preferred_3_time,
}: { 
  register: any, 
  preferred_1_date: any, 
  preferred_1_time: any, 
  preferred_2_date: any, 
  preferred_2_time: any, 
  preferred_3_date: any, 
  preferred_3_time: any, 
}) => {
  const options = [
    { id: 15, title: '小学校準備講座', },
    { id: 14, title: 'English Fun Time', },
    // { id: 1, title: '【わくわく】プログラ道場(夏の有料イベント)', },
    // { id: 2, title: '【わくわく】恐竜をつくろう(夏の有料イベント)', },
    // { id: 3, title: '【わくわく】恐竜を動かそう(夏の有料イベント)', },
    { id: 4, title: '【無料】プログラ道場', },
    { id: 5, title: '【無料】自考力キッズ（60分）', },
    { id: 6, title: '【無料】エジソンアカデミー（60分）', },
    // { id: 7, title: '【無料】飛び級教室・個別保護者説明会（60分）', },
    { id: 8, title: '【無料】デジそろ（30分）', },
    { id: 9, title: '【無料】速読解力講座（45分）', },
    { id: 10, title: '【無料】思考力講座（45分）', },
    { id: 11, title: '【無料】速読聴英語講座（45分）', },
    { id: 12, title: '【無料】社会人速読講座（45分）', },
    // { id: 13, title: '【無料】わくわく英語講座', },
  ]
  const style_textfield = [
    'appearance-none',
    'block',
    'w-full',
    'text-gray-700',
    'border',
    'border-gray-200',
    'rounded-sm',
    'leading-tight',
    'focus:outline-none',
    'focus:bg-white'
  ]
  return (
    <>
      <div className="flex mt-3">
        <div className="flex-col">
          {options.map((option: any) => (
            <div className="flex items-center mr-4 mb-2">
              <input {...register} type="radio" value={option.id} className={Arr2str(style)} />
              <label className="ml-2 text-sm font-medium">
                {option.title}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex mt-3">
        <div className="flex-col">
          <div className="grid grid-cols-1 gap-4 text-left grid-cols-3">
            <div>
              第１希望日：
            </div>
            <div>
              <input {...preferred_1_date} type="text" placeholder={`例）4/1`} className={Arr2str(style_textfield)} />
            </div>
            <div>
              <input {...preferred_1_time} type="text" placeholder={`例）13:00`} className={Arr2str(style_textfield)} />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 text-left grid-cols-3">
            <div>
              第２希望日：
            </div>
            <div>
              <input {...preferred_2_date} type="text" placeholder={`例）4/1`} className={Arr2str(style_textfield)} />
            </div>
            <div>
              <input {...preferred_2_time} type="text" placeholder={`例）14:00`} className={Arr2str(style_textfield)} />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 text-left grid-cols-3">
            <div>
              第３希望日：
            </div>
            <div>
              <input {...preferred_3_date} type="text" placeholder={`例）4/2`} className={Arr2str(style_textfield)} />
            </div>
            <div>
              <input {...preferred_3_time} type="text" placeholder={`例）13:00`} className={Arr2str(style_textfield)} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const Textfield = ({ register, placeholder }: { register: any, placeholder: any }) => {
  const style = [
    'appearance-none',
    'block',
    'w-full',
    'text-gray-700',
    'border',
    'border-gray-200',
    'rounded-lg',
    'py-3',
    'px-4',
    'leading-tight',
    'focus:outline-none',
    'focus:bg-white'
  ]
  return (
    <>
      <input {...register} type="text" placeholder={placeholder} className={Arr2str(style)} />
    </>
  )
}

export const ZipcodeForm = ({ register, value, placeholder, onChange }: { register: any, value: any, placeholder: any, onChange: any }) => {
  const style = [
    'appearance-none',
    'block',
    'w-full',
    'text-gray-700',
    'border',
    'border-gray-200',
    'rounded-lg',
    'py-3',
    'px-4',
    'leading-tight',
    'focus:outline-none',
    'focus:bg-white'
  ]
  return (
    <>
      {(onChange == null ? (
        <input {...register} type="text" value={value} placeholder={placeholder} className={Arr2str(style)} />
      ) : (
        <input {...register} type="text" value={value} placeholder={placeholder} onChange={onChange} className={Arr2str(style)} />
      ))}
    </>
  )
}

export const Textarea = ({ register }: { register: any }) => {
  const style = [
    'appearance-none',
    'block',
    'w-full',
    'bg-white',
    'text-gray-700',
    'border',
    'border-gray-200',
    'py-3',
    'px-4',
    'leading-tight',
    'focus:outline-none',
    'focus:bg-white'
  ]
  return (
    <>
      <textarea {...register} className={Arr2str(style)} rows={5} />
    </>
  )
}
