import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { Slider1 } from './elements/Slider'
import { H1, H2 } from './elements/Heading'
import { Youtube2 } from './elements/Youtube'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/robot-programming/1.png'
import Img2 from '../img/robot-programming/2.jpeg'
import Img3 from '../img/robot-programming/3.png'
import Img4 from '../img/robot-programming/4.jpeg'
import Swiper1 from '../img/robot-programming/slider1.png'
import Swiper2 from '../img/robot-programming/slider2.png'
import Swiper3 from '../img/robot-programming/slider3.png'
import Swiper4 from '../img/robot-programming/slider4.png'
import Swiper5 from '../img/robot-programming/slider5.png'
import Swiper6 from '../img/robot-programming/slider6.png'
import Swiper7 from '../img/robot-programming/slider7.png'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { IconContext }    from 'react-icons'

export default function RobotProgramming () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
      <Frame>
        <H1>
          「論理的思考力・問題解決能力」の育成
        </H1>
      </Frame>
      <Frame>
        <img src={Img1} className=" w-full object-cover object-center" alt=""/>
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "4_mh6lXF7YU", title: "プログラミングって何？"},
          { id: "aPTiKc57-2c", title: "ロボットプログラミング体験会 保護者資料" },
        ]} height={'300px'}/>
      </Frame>
      <Frame>
        <H2>
          いま、なぜプログラミング教育が必要？
        </H2>
      </Frame>
      <Frame>
        <div className="flex flex-wrap">
          <div className="w-screen">
            <div className="divide-y">
              <div className="pt-3 pb-3">
                <h1 className="text-xl font-medium text-gray-900 text-left">
                  プログラミング教育が学校で始まる！
                </h1>
              </div>
              <div>
                <div className="flex flex-col sm:flex-row mt-5 mb-5">
                  <div className="sm:w-1/2">
                    <ul>
                      <li className="m-3 text-md">
                        小学校から高校まで、文系・理系を問わず、
                        子どもたち全員がプログラミングを学習することが決まっており、
                        AIやビッグデータなどのIT、情報処理の素養はこれからの時代の
                        「読み書きそろばん」と考えられています。
                      </li>
                      <li className="m-3 p-3 bg-gray-300">
                        ・小学校：2020年度より必修化<br/>
                        ・中学校：2021年度よりプログラミング学習強化<br/>
                        （2012年度より技術科でプログラミング必修）<br/>
                        ・高　校:2022年度より共通必履修科目として「情報Ⅰ」新設
                      </li>
                    </ul>
                  </div>
                  <div className="sm:w-1/2 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                    <img src={Img2} className=" w-full object-cover object-center" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Frame>
      <Frame>
        <div className="flex flex-wrap">
          <div className="w-screen">
            <div className="divide-y">
              <div className="pt-3 pb-3">
                <h1 className="text-xl font-medium text-gray-900 text-left">
                  プログラミング教育が学校で始まる！
                </h1>
              </div>
              <div>
                <div className="flex flex-col sm:flex-row mt-5 mb-5">
                  <div className="sm:w-2/2">
                    <ul>
                      <li className="m-3 text-md">
                        大学入試センター試験に代わって導入される「大学入学共通テスト」に、国語・数学・英語のような基礎的な科目として、
                        プログラミングなどの情報科目の導入が検討されています。<br/>
                        導入の背景には、人工知能(ＡＩ）などを使いこなせるＩＴ人材の不足があります。経済産業省によると、
                        ＩＴ人材は2015年時点で国内で15万人不足しており、2030年には最大79万人不足する見込みといわれています。
                        10～20年後には現在存在している職業の半分はロボットに奪われ、人間の仕事がなくなると言われているのとは対照的です。
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Frame>
      <Frame>
        <Slider1 slides={[
          Swiper1,
          Swiper2,
          Swiper3,
          Swiper4,
          Swiper5,
          Swiper6,
          Swiper7,
        ]}/>
      </Frame>
      <Frame>
        <H2>
          小学生から始められる「ステップアップ方式」
        </H2>
        <div className="m-3">
          小学生でも簡単に組み立てられるブロック型のロボットを使い、
          プログラミングした動作を実際にさせながら学習を進めます。<br/>
          マサチューセッツ工科大が開発した小学生向けソフトでプログラムをするので、<br/>
          直感的な操作で本格的なプログラムを組むことができます。
        </div>
      </Frame>
      <Frame>
        <img src={Img3} className=" w-full object-cover object-center" alt=""/>
      </Frame>
      <Frame>
        <H2>
          考え抜く力が身につく！
        </H2>
        <div className="m-3">
          プログラミングとは、コンピューターを動かす「プログラム」を作ることであり、<br/>
          「プログラム」はコンピューターにさせたいことの手順や命令を記した指示の集合です。<br/>
          完成までの流れで、論理的思考力と問題解決能力が育成されるため、今、注目されています。
        </div>
      </Frame>
      <Frame>
        <img src={Img4} className=" w-full object-cover object-center" alt=""/>
      </Frame>
      <Frame>
        <H2>
          受講料
        </H2>
        <div className="m-3 leading-loose">
          ロボットプログラミング：<br/>
          ・月額制 ￥14,300（税込）/月３回<br/>
          ・教材費 ￥47,300（税込・初回のみ）
        </div>
        <div className="m-3">
          <div className="flex justify-center">
            <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
              <IoIosArrowDroprightCircle />
              <span className="text-xl ml-1"> お申し込み </span>
            </a>
          </div>
        </div>
      </Frame>
      <Frame>
        <H1>
          ロボットコンテスト 参加！
        </H1>
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "A9BXKnwCWsA", title: null},
          { id: "D4A26z4Fl1g", title: null },
        ]} height={'300px'}/>
      </Frame>
      <Frame>
        <H1>
          プログラミング ～子どもたちの成長記録～
        </H1>
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "4T5--tAGstU", title: "対戦ゲーム ブロックを集めよう！" },
          { id: "ILXUoCWLn4s", title: "輪ゴムランチャーで射撃ゲーム！" },
          { id: "FOzHn2HJ0qM", title: "タイムトライアル 荷物積みバトルで新記録！" },
          { id: "SHilHCxaDI0", title: "剣道対決 ビートルロボットカーで対戦ゲーム" },
          { id: "IdAxw893fRY", title: "カタパルト(ブロック投戦車)で対戦ゲーム！" },
          { id: "mP7peAnJqyM", title: "クレーン車でブロック落としゲームに挑戦！" },
          { id: "_BtgUZtKGa4", title: "クレーンアームロボットで荷物を運ぼう！" },
          { id: "PhBHagiP9WI", title: "ライントレース自動車" },
          { id: "VZLUC8FbyTg", title: "ウォールトレース戦車" },
          { id: "IqoY0iqvWC4", title: "より複雑なウォールトレース自動車" },
          { id: "2N-gT7q5hCw", title: "衝突を防ぐ自動車" },
          { id: "hwi6-qL94WA", title: "二足歩行ロボット まさむねくん" },
          { id: "pFHFWemZwXY", title: "六足歩行ロボット" },
          { id: "nQ1nYklMnqU", title: "お絵かきロボット" },
          { id: "Eapofws-V9M", title: "クレーンゲーム機" },
          { id: "GSFZn19pndg", title: "動く射的ゲーム機" },
          { id: "zguFaW2iVvo", title: "かみつき番犬ロボ ゲーム" },
          { id: "PokugrJjsZY", title: "連打チャレンジ「鬼退治ゲーム」" },
          { id: "ycwIba_kmHc", title: "エキスパート・自動改札機" },
          { id: "0yOwOjOVZzc", title: "エキスパート・速度計測器" },
          { id: "LbFB1ytUHoA", title: "ウォーキングロボット" },
          { id: "8z-BR1KyFks", title: "ブロック落としゲーム" },
        ]} height={'300px'}/>
      </Frame>

      <Footer />
    </>
  )
}
