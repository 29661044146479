import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/programming/jikoryoku.gif'
import Img2 from '../img/programming/robot.png'
export default function Blog () {
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            ブログ
          </H1>
        </Frame>
        <Frame>
          工事中
        </Frame>
      <Footer />
    </>
  )
}
