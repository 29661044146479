import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { Slider1 } from './elements/Slider'
import { H1, H2 } from './elements/Heading'
import { Youtube1, Youtube2 } from './elements/Youtube'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/jikoryoku-kids/1.jpeg'
import Img2 from '../img/jikoryoku-kids/2.gif'
import Img3 from '../img/jikoryoku-kids/3.jpeg'
import Img4 from '../img/jikoryoku-kids/4.gif'
import Img5 from '../img/jikoryoku-kids/5.gif'
import Img6 from '../img/jikoryoku-kids/6.gif'
import Swiper1 from '../img/jikoryoku-kids/swiper1.png'
import Swiper2 from '../img/jikoryoku-kids/swiper2.png'
import Swiper3 from '../img/jikoryoku-kids/swiper3.png'
import Swiper4 from '../img/jikoryoku-kids/swiper4.png'
import Swiper5 from '../img/jikoryoku-kids/swiper5.png'
import Swiper6 from '../img/jikoryoku-kids/swiper6.png'
import Swiper7 from '../img/jikoryoku-kids/swiper7.png'
import Swiper8 from '../img/jikoryoku-kids/swiper8.png'
import Swiper9 from '../img/jikoryoku-kids/swiper9.png'
import Swiper10 from '../img/jikoryoku-kids/swiper10.jpeg'
import Swiper11 from '../img/jikoryoku-kids/swiper11.jpeg'
import Swiper12 from '../img/jikoryoku-kids/swiper12.jpeg'
import Swiper13 from '../img/jikoryoku-kids/swiper13.jpeg'
import Swiper14 from '../img/jikoryoku-kids/swiper14.jpeg'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { IconContext }    from 'react-icons'
export default function RobotProgramming () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
      <Frame>
        <H1>
          自考力キッズ（年長～小学低学年）
        </H1>
      </Frame>
      <Frame>
        <img src={Img1} className=" w-full object-cover object-center" alt=""/>
      </Frame>
      <Frame>
        <Youtube1 urls={[
          {id: "6eNHaxXNvJg"}
        ]} height={'500px'}/>
      </Frame>
      <Frame>
        <div className="flex flex-wrap">
          <div className="w-screen">
            <div className="divide-y">
              <div className="flex flex-col sm:flex-row">
                <div className="sm:w-3/4 m-2">
                  <img src={Img2} className="w-full object-cover object-center" alt=""/>
                </div>
                <div className="sm:w-1/4">
                  <img src={Img3} className="w-full object-cover object-center" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Frame>
      <Frame>
        <div className="flex flex-wrap">
          <div className="w-screen">
            <div className="divide-y">
              <div className="flex flex-col sm:flex-row">
                <div className="sm:w-1/3">
                  <img src={Img4} className="w-full object-cover object-center" alt=""/>
                </div>
                <div className="sm:w-1/3">
                  <img src={Img5} className="w-full object-cover object-center" alt=""/>
                </div>
                <div className="sm:w-1/3">
                  <img src={Img6} className="w-full object-cover object-center" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Frame>
      <Frame>
        <H2>
          受講料
        </H2>
        <div className="m-3 leading-loose">
          自考力キッズ：<br/>
          ・月会費 ￥12,100（税込）/月３回<br/>
          ・教材費 ￥35,200（税込・初回のみ）
        </div>
        <div className="m-3">
          <div className="flex justify-center">
            <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
              <IoIosArrowDroprightCircle />
              <span className="text-xl ml-1"> お申し込み </span>
            </a>
          </div>
        </div>
      </Frame>
      <Frame>
        <Slider1 slides={[
          Swiper1,
          Swiper2,
          Swiper3,
          Swiper4,
          Swiper5,
          Swiper6,
          Swiper7,
          Swiper8,
          Swiper9,
        ]}/>
      </Frame>
      <Frame>
        <Slider1 slides={[
          Swiper10,
          Swiper11,
          Swiper12,
          Swiper13,
          Swiper14,
        ]}/>
      </Frame>
      <Frame>
        <H1>
          自考力キッズ ～子どもたちの成長記録～
        </H1>
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "ZBsoiriZBVA", title: "消防車をつくろう！" },
          { id: "KM7KL3Ovtuw", title: "オリジナルで回転するくるま！" },
          { id: "GZdzx0hrRKE", title: "坂道をのぼらせよう！" },
          { id: "zdfjWTDexYA", title: "ブロックをよけて買い物に行こう！" },
          { id: "2QYXaUmuLZo", title: "リザードン号で荷物を運ぼう" },
          { id: "TH8SWPz-iWM", title: "すもうロボットで対決！" },
          { id: "EzYyJUf3Pw8", title: "算数パズル・はぐるまの回転" },
          { id: "jfUSQ3prMeU", title: "ロボットカーを自由に動かそう" },
          { id: "f5zA_xZ8lRk", title: "プログラミング 車で絵を書いてみよう" },
          { id: "felhqIaHCog", title: "ダンスロボットをつくろう！" },
          { id: "nQM_MRpZPuQ", title: "モノレールをつくろう！" },
          { id: "rOjJ4c60a_Q", title: "つなわたりロボットをつくろう" },
          { id: "6PW759M8R5Y", title: "クルマをそうじゅうしよう！" },
          { id: "hItf_nPgY3I", title: "オリジナル・ワンダーパーク" },
        ]} height={'300px'}/>
      </Frame>
      <Footer />
    </>
  )
}
