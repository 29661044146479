import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1, H2 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import { Youtube2 } from './elements/Youtube'
import Img1 from '../img/sokudokkairyoku/1.png'
import Img2 from '../img/sokudokkairyoku/2.png'
import Img3 from '../img/sokudokkairyoku/3.png'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { IconContext }    from 'react-icons'
export default function Dokkairyoku () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            速読解力講座（小中高生）
          </H1>
        </Frame>
        <Frame>
          <img src={Img1} className="w-full max-w-xl m-auto" alt=""/>
        </Frame>
        <Frame>
          <Youtube2 urls={[
            { id: "yiGkqlNlM9I" },
            { id: "xAbucXFfPGg" },
          ]} height={'300px'}/>
        </Frame>
        <Frame>
          <img src={Img2} className="w-full m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img3} className="w-full m-auto" alt=""/>
        </Frame>
        <Frame>
          <H2>
            受講料
          </H2>
          <div className="m-3 leading-loose">
          ・月額制＜１回60分 月４回＞<br/>
          　速読解力講座：￥13,200　思考力講座：￥13,200<br/>
          ・月額制＜１回90分＞同時申込割引適用　￥19,800<br/>
          </div>
          <div className="m-3">
            <div className="flex justify-center">
              <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
                <IoIosArrowDroprightCircle />
                <span className="text-xl ml-1"> お申し込み </span>
              </a>
            </div>
          </div>
        </Frame>
      <Footer />
    </>
  )
}
