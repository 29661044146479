import React      from 'react'
import { useNavigate }  from "react-router-dom";
import { Routes } from "react-router-dom"
import { Route }  from "react-router-dom"

import Home              from './components/Home'
import Programming       from './components/Programming'
import RobotProgramming  from './components/RobotProgramming'
import JikoryokuKids     from './components/JikoryokuKids'
import Sokudoku          from './components/Sokudoku'
import Dokkairyoku       from './components/Dokkairyoku'
import Shikoryoku        from './components/Shikoryoku'
import Sokudokucho       from './components/Sokudokucho'
import Syakaijin         from './components/Syakaijin'
import DigiSoro          from './components/DigiSoro'
import PrograDojo        from './components/PrograDojo'
import Blog              from './components/Blog'
import TrialContact      from './components/TrialContact'
import Eisai             from './components/Eisai'
import ElementaryEnglish from './components/ElementaryEnglish'
import Gallery           from './components/Gallery'
import Machigai          from './components/Machigai'
import Privacy           from './components/Privacy'

function App() {
  const navigate = useNavigate()
  return (
    <div className="App">
      <Routes>
        <Route path="/"                               element={<Home />}              />
        <Route path="/programming"                    element={<Programming />}       />
        <Route path="/programming/robot-programming"  element={<RobotProgramming />}  />
        <Route path="/programming/jikoryoku-kids"     element={<JikoryokuKids />}     />
        <Route path="/sokudoku"                       element={<Sokudoku />}          />
        <Route path="/sokudoku/elementary-english"    element={<ElementaryEnglish />} />
        <Route path="/sokudoku/sokudokkairyoku"       element={<Dokkairyoku />}       />
        <Route path="/sokudoku/shikoryoku"            element={<Shikoryoku />}        />
        <Route path="/sokudoku/sokudokucho-english"   element={<Sokudokucho />}       />
        <Route path="/sokudoku/syakaijin"             element={<Syakaijin />}         />
        <Route path="/digi-soro"                      element={<DigiSoro />}          />
        <Route path="/progra-dojo"                    element={<PrograDojo />}        />
        <Route path="/blog"                           element={<Blog />}              />
        <Route path="/trial-contact"                  element={<TrialContact />}      />
        <Route path="/eisai"                          element={<Eisai />}             />
        <Route path="/gallery"                        element={<Gallery />}           />
        <Route path="/machigai"                       element={<Machigai />}          />
        <Route path="/privacy"                        element={<Privacy />}           />
        <Route path="*" element={
          <div className="p-4 cursor">
            <a className="text-blue-600" onClick={()=>navigate(`/`)}>
              ホームへ戻る
            </a>
          </div>} />
      </Routes>
    </div>
  );
}

export default App;
