import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import { Youtube1 } from './elements/Youtube'
import { Slider1 } from './elements/Slider'
import Hukei1 from '../img/gallery/hukei1.jpeg'
import Hukei2 from '../img/gallery/hukei2.jpeg'
import Hukei3 from '../img/gallery/hukei3.jpeg'
import Hukei4 from '../img/gallery/hukei4.jpeg'
import Hukei5 from '../img/gallery/hukei5.jpeg'
import Hukei6 from '../img/gallery/hukei6.jpeg'
import Hukei7 from '../img/gallery/hukei7.jpeg'
import Hukei8 from '../img/gallery/hukei8.jpeg'
import Nakama1 from '../img/gallery/nakama1.jpeg'
import Nakama2 from '../img/gallery/nakama2.jpeg'
import Prog1 from '../img/gallery/prog1.jpeg'
import Prog2 from '../img/gallery/prog2.jpeg'
import Prog3 from '../img/gallery/prog3.jpeg'
import Prog4 from '../img/gallery/prog4.jpeg'
import Prog5 from '../img/gallery/prog5.jpeg'
import Waku1 from '../img/gallery/wakuwaku1.jpeg'
import Waku2 from '../img/gallery/wakuwaku2.jpeg'
import Waku3 from '../img/gallery/wakuwaku3.jpeg'
import Waku4 from '../img/gallery/wakuwaku4.jpeg'
import Waku5 from '../img/gallery/wakuwaku5.jpeg'
import Waku6 from '../img/gallery/wakuwaku6.jpeg'
import Waku7 from '../img/gallery/wakuwaku7.jpeg'
import Waku8 from '../img/gallery/wakuwaku8.jpeg'
import Waku9 from '../img/gallery/wakuwaku9.jpeg'
import Waku10 from '../img/gallery/wakuwaku10.jpeg'
import Waku11 from '../img/gallery/wakuwaku11.jpeg'
import Waku12 from '../img/gallery/wakuwaku12.jpeg'
import Waku13 from '../img/gallery/wakuwaku13.jpeg'
import Paper1 from '../img/gallery/paper1.jpeg'
import Paper2 from '../img/gallery/paper2.jpeg'
import Paper3 from '../img/gallery/paper3.jpeg'
import Paper4 from '../img/gallery/paper4.jpeg'
import Paper5 from '../img/gallery/paper5.jpeg'
import Paper6 from '../img/gallery/paper6.jpeg'
import Paper7 from '../img/gallery/paper7.jpeg'
import Book1 from '../img/gallery/book1.jpeg'
import Book2 from '../img/gallery/book2.jpeg'
import Book3 from '../img/gallery/book3.jpeg'
import Book4 from '../img/gallery/book4.jpeg'
import Sokudoku1 from '../img/gallery/sokudoku1.jpeg'
import Sokudoku2 from '../img/gallery/sokudoku2.jpeg'
import Sokudoku3 from '../img/gallery/sokudoku3.jpeg'
import Sokudoku4 from '../img/gallery/sokudoku4.jpeg'
import Sokudoku5 from '../img/gallery/sokudoku5.jpeg'
import Sokudoku6 from '../img/gallery/sokudoku6.jpeg'
export default function Gallery () {
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            ギャラリー
          </H1>
        </Frame>
        <Frame>
          <Youtube1 urls={[
            {id: "6U2c2hxYgn4"}
          ]} height={'500px'}/>
        </Frame>
        <Frame>
          <H1>
            「デジそろ」の風景
          </H1>
        </Frame>
        <Frame>
          <Slider1 slides={[
            Hukei1,
            Hukei2,
            Hukei3,
            Hukei4,
            Hukei5,
            Hukei6,
            Hukei7,
            Hukei8,
          ]}/>
        </Frame>
        <Frame>
          <H1>
            てらっこの仲間たち
          </H1>
        </Frame>
        <Frame>
          <Slider1 slides={[
            Nakama1,
            Nakama2,
          ]}/>
        </Frame>
        <Frame>
          <H1>
            プログラミングの風景
          </H1>
        </Frame>
        <Frame>
          <Slider1 slides={[
            Prog1,
            Prog2,
            Prog3,
            Prog4,
            Prog5,
          ]}/>
        </Frame>
        <Frame>
          <H1>
            ワクワクプロジェクトの恐竜たち
          </H1>
        </Frame>
        <Frame>
          <Slider1 slides={[
            Waku1,
            Waku2,
            Waku3,
            Waku4,
            Waku5,
            Waku6,
            Waku7,
            Waku8,
            Waku9,
            Waku10,
            Waku11,
            Waku12,
            Waku13,
          ]}/>
        </Frame>
        <Frame>
          <H1>
            子どもたちに大人気・ペーパークラフト
          </H1>
        </Frame>
        <Frame>
          <Slider1 slides={[
            Paper1,
            Paper2,
            Paper3,
            Paper4,
            Paper5,
            Paper6,
            Paper7,
          ]}/>
        </Frame>
        <Frame>
          <H1>
            書籍
          </H1>
        </Frame>
        <Frame>
          <Slider1 slides={[
            Book1,
            Book2,
            Book3,
            Book4,
          ]}/>
        </Frame>
        <Frame>
          <H1>
            速読の風景
          </H1>
        </Frame>
        <Frame>
          <Slider1 slides={[
            Sokudoku1,
            Sokudoku2,
            Sokudoku3,
            Sokudoku4,
            Sokudoku5,
            Sokudoku6,
          ]}/>
        </Frame>
      <Footer />
    </>
  )
}
