import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1, H2 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import { Youtube1 } from './elements/Youtube'
import Img1 from '../img/sokudoku/1_sokudokkairyoku.png'
import Img2 from '../img/sokudoku/2_shikouryokukouza.png'
import Img3 from '../img/sokudoku/3_sokudokuchoeigo.png'
import Img4 from '../img/sokudoku/4_shakaijinsokudoku.png'

import Leaflet1 from '../img/sokudoku/leaflet1.jpeg'
import Leaflet2 from '../img/sokudoku/leaflet2.jpeg'
import Leaflet3 from '../img/sokudoku/leaflet3.jpeg'
export default function Sokudoku () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
      <Frame>
        <H1>
          速読解講座・思考力講座・速読聴英語講座
        </H1>
      </Frame>
      <Frame>
        <H2>
         小・中・高校生 対象
        </H2>
      </Frame>

      <Frame>
        <div className="grid gap-2 sm:grid-cols-2">
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <img src={Img1} className="mb-3 cursor" alt="" onClick={()=>navigate(`/sokudoku/sokudokkairyoku`)} />
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <iframe
                  src={`https://www.youtube.com/embed/${'xAbucXFfPGg'}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  width="100%"
                  height="225px"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <img src={Img2} className="mb-3 cursor" alt="" onClick={()=>navigate(`/sokudoku/shikoryoku`)} />
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <iframe
                  src={`https://www.youtube.com/embed/${'HJCagHI1scA'}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  width="100%"
                  height="225px"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <img src={Img3} className="mb-3 cursor" alt="" onClick={()=>navigate(`/sokudoku/sokudokucho-english`)} />
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <iframe
                  src={`https://www.youtube.com/embed/${'cMvNN2SYXYE'}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  width="100%"
                  height="225px"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Frame>

      <Frame>
        <H2>
         社会人 対象
        </H2>
      </Frame>

      <Frame>
        <div className="grid gap-2 sm:grid-cols-2">
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <img src={Img4} className="mb-3 cursor" alt="" onClick={()=>navigate(`/sokudoku/syakaijin`)} />
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <div className="rounded-xl">
                <iframe
                  src={`https://www.youtube.com/embed/${'xAbucXFfPGg'}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  width="100%"
                  height="225px"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Frame>
      <Frame>
        <H1>
          速読リーフレット ダウンロード
        </H1>
      </Frame>
      <Frame>
        <div className="grid gap-2 sm:grid-cols-3">
          <div>
            <div className="p-2">
              <H2>
               小学生
              </H2>
              <div className="rounded-xl">
                <a href="/pdf/leaflet_elemental.pdf" download >
                  <img src={Leaflet1} className="mb-3 cursor" alt=""/>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <H2>
               中学生
              </H2>
              <div className="rounded-xl">
                <a href="/pdf/leaflet_juniorhigh.pdf" download >
                  <img src={Leaflet2} className="mb-3 cursor" alt=""/>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="p-2">
              <H2>
               高校生
              </H2>
              <div className="rounded-xl">
                <a href="/pdf/leaflet_highschool.pdf" download >
                  <img src={Leaflet3} className="mb-3 cursor" alt=""/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Frame>

      <Footer />
    </>
  )
}
