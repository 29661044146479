import { useNavigate } from "react-router-dom";
import Logo from '../../img/logo.jpeg'
export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto">
      <div className="lg:w-11/12 mx-auto">
        <div className="lg:w-3/4 mx-auto p-5">

          <div className="md:flex md:justify-between">

            <div className="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3">

              <div className="m-3">

                <a href="https://flowbite.com/" className="flex items-center">
                  <img src={Logo} width="70%" alt="アイコン" />
                  <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
                </a>

                <div className="p-3">
                  <p className="m-1">てらっこクラブ　会社概要</p>
                  <p className="m-1">〒140-0011</p>
                  <p className="m-1">品川区東大井5丁目2-7　磯部ビル202</p>
                  <p className="m-1">・TEL：03-6712-0333</p>
                  <p className="m-1">（受付時間：11:00~18:00）</p>
                  <p className="m-1">・休日：毎週月曜日</p>
                </div>

                <div className="mb-3">
                  <button className="w-full bg-yellow-300 text-orange-500 text-bold border border-red-700 p-3 rounded-lg" onClick={() => navigate(`/blog`)}>blog（ブログ）</button>
                </div>

                <div className="mb-3">
                  <button className="w-full bg-pink-100 text-pink-500 text-bold border border-red-700 p-3 rounded-lg" onClick={() => navigate(`/trial-contact`)}>体験会申し込み</button>
                </div>

              </div>

              <div className="m-3">
                {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6487.609629237711!2d139.736163!3d35.607881!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd521f984b80bc1f0!2z5p2x5Lqs6YO956uL5ZOB5bed54m55Yil5pSv5o-05a2m5qCh!5e0!3m2!1sja!2sus!4v1669101090371!5m2!1sja!2sus"
                  width="300"
                  height="400"
                  scrolling="no"
                  allow="encrypted-media"
                ></iframe> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12975.21920982239!2d139.7361634!3d35.6078813!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b427c8715b1%3A0x48f4950607915c45!2z44Gm44KJ44Gj44GT44Kv44Op44OWIEVuZ2xpc2ggRnVuIFRpbWU!5e0!3m2!1sja!2sjp!4v1691116697540!5m2!1sja!2sjp"
                  width="300"
                  height="400"
                  scrolling="no"
                  allow="encrypted-media"
                ></iframe>
              </div>

              <div className="m-3">
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fterracco.club&amp;tabs=timeline&amp;width=300&amp;height=400&amp;small_header=true&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=false&amp;appId=331345546940325"
                  width="300"
                  height="400"
                  scrolling="no"
                  allow="encrypted-media"
                ></iframe>
              </div>

            </div>
          </div>

          <hr className="my-4 border-gray-200 sm:mx-auto dark:border-gray-700" />

          <div className="sm:flex sm:items-center sm:justify-between">

            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://flowbite.com/" className="hover:underline">Terra-Cco CLUB（テラッコ・クラブ）</a></span>

          </div>

        </div>
      </div>
    </div>
  )
}
