export default function Frame (props: any) {
  return (
    <div className="container mx-auto">
      <div className="lg:w-11/12 mx-auto">
        <div className="lg:w-3/4 p-3 mx-auto">
          {props.children}
        </div>
      </div>
    </div>
  )
}
