import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { ScrollTop } from './utils/ScrollTop'
import { Slider1 } from './elements/Slider'
import { H1, H3 } from './elements/Heading'
import { IconContext }         from 'react-icons'
import { MdDeleteOutline }     from "react-icons/md"
import { BsSignpost2 }         from "react-icons/bs"
import { TbAddressBook }       from "react-icons/tb"
import { AiOutlineHome }       from "react-icons/ai"
import { AiOutlineHeart }      from "react-icons/ai"
import { MdOutlineCategory }   from "react-icons/md"
import { BsArrowRightCircle }  from "react-icons/bs"
import { TbBuildingWarehouse } from "react-icons/tb"
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import Img1 from '../img/digi-soro/1.jpeg'
import Img2 from '../img/digi-soro/2.png'
import Swiper1 from '../img/digi-soro/swiper1.png'
import Swiper2 from '../img/digi-soro/swiper2.png'
import Swiper3 from '../img/digi-soro/swiper3.png'
import Swiper4 from '../img/digi-soro/swiper4.png'

export default function DigiSoro () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
      <Frame>
        <H1>
          デジタルそろばんコース
        </H1>
      </Frame>
      <Frame>
        <Slider1 slides={[
          Swiper1,
          Swiper2,
          Swiper3,
          Swiper4,
        ]}/>
      </Frame>
      <Frame>
        <H1>デジタルそろばんコース</H1>
      </Frame>
      <Frame>
        <H3>
          対象：
          <span className="text-sm border rounded-lg p-1 mr-1 ml-1">年中</span>〜
          <span className="text-sm border rounded-lg p-1 mr-1 ml-1">無学年</span>
          <span className="text-sm border rounded-lg p-1 mr-1 ml-1">社会人</span>
          <span className="text-sm border rounded-lg p-1 mr-1 ml-1">シニア</span>
        </H3>
      </Frame>
      <Frame>
            <div className="flex flex-wrap">
              <div className="w-screen">
                <div className="divide-y">
                  <div className="pt-3 pb-3">
                    <h1 className="text-2xl font-medium text-gray-900 text-left">
                      デジタルそろばんコース
                    </h1>
                  </div>
                  <div>
                    <div className="flex flex-col sm:flex-row mt-5 mb-5">
                      <div className="sm:w-2/3">
                        <ul>
                          <li className="m-3 text-xl">
                            能力開発＋数理的な考え方の基礎を養成
                          </li>
                          <li className="m-3">
                            計算・算数基礎力を身につける
                          </li>
                          <li className="m-3">
                            ・機能１　そろばんの基本
                          </li>
                          <li className="m-3">
                            ・機能２　フラッシュけいさん
                          </li>
                          <li className="m-3">
                            ・機能３　よみあげざん・よみあげあんざん
                          </li>
                          <li className="m-3">
                            ・機能４　すうトレ
                          </li>
                          <li className="m-3">
                            「月額制・週１回60分」「デジそろ検定　15級～1級」
                          </li>
                        </ul>
                      </div>
                      <div className="sm:w-1/3 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <img src={Img2} className=" w-full object-cover object-center" alt=""/>
                        <img src={Img1} className=" w-full object-cover object-center" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </Frame>
      <Frame>
            <div className="flex flex-wrap">
              <div className="w-screen">
                <div className="text-lg">
                  <div className="mt-3 mb-3">
                    デジそろは、右脳開発を目的とした学習で暗記能力を養います。
                    珠算式計算と陰山式（陰山 英男 先生）数トレを交互に取り組むことで、珠算の四則演算の理解と集中力を高めることができます。
                    楽しみながら計算力を上げて行きましょう。ロボットプログラミング「自考力キッズ」と組み合わせて、
                    お子さまの興味の幅を広げることができます。
                  </div>
                  <div className="mt-5 mb-5">
                    <span className="text-sm text-white border rounded-lg bg-orange-600 p-1 mr-1 ml-1">デジタルそろばん速習教室　デジソロ</span>
                    <span className="text-sm text-white border rounded-lg bg-orange-600 p-1 mr-1 ml-1">陰山式数トレ×デジタルそろばん速習教室</span>
                  </div>
                  <div className="mt-3 mb-3">
                    総監修：教育クリエーター　陰山英男先生 <br />
                    システム監修：珠算十段・暗算十段保持　松井正弘先生
                  </div>
                </div>
              </div>
            </div>
      </Frame>
      <Frame>
            <div className="flex flex-wrap">
              <div className="w-screen">
                <div className="divide-y">
                  <div className="pt-3 pb-3">
                    <h1 className="text-2xl font-medium text-gray-900 text-left">
                      受講料
                    </h1>
                  </div>
                  <div>
                    <div className="flex flex-col sm:flex-row mt-5 mb-5">
                      <div className="sm:w-1/2">
                        <ul>
                          <li className="m-3 text-xl">
                            デジそろ：
                          </li>
                          <li className="m-3">
                            ・月会費 ￥5,500（税込）/ １回 60分 月４回
                          </li>
                          <li className="m-3">
                            ・教材費 ￥8,250（税込・初回のみ）
                          </li>
                          <li className="m-3">
                            <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
                              <IoIosArrowDroprightCircle />
                              <span className="text-xl ml-1"> お申し込み </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="sm:w-1/2 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <iframe
                          src={`https://www.youtube.com/embed/${'dcMmYyDf1Fk'}`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          width="100%"
                          height="220px"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </Frame>
      <Footer />
    </>
  )
}
