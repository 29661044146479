import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1, H2 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/elementary-english/1.png'
import Img2 from '../img/elementary-english/2.png'
import Img3 from '../img/elementary-english/3.png'
import Img4 from '../img/elementary-english/4.png'
import Img5 from '../img/elementary-english/5.png'
import Img6 from '../img/elementary-english/6.png'
import Img7 from '../img/elementary-english/7.png'
import Img8 from '../img/elementary-english/8.png'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
export default function Blog () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            わくわく英語講座
          </H1>
        </Frame>
        <Frame>
          <img src={Img1} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <img src={Img2} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <img src={Img3} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <img src={Img4} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <H2>
            「楽しい！」「またやりたい！」英語が好きになるレッスン
          </H2>
        </Frame>
        <Frame>
          <img src={Img5} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <img src={Img6} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <H2>
            レッスン時間にアプリを開くだけ！面倒な設定なしで受講！
          </H2>
        </Frame>
        <Frame>
          <img src={Img7} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <img src={Img8} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <div className="m-3">
            <div className="flex justify-center">
              <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
                <IoIosArrowDroprightCircle />
                <span className="text-xl ml-1"> 体験申し込み </span>
              </a>
            </div>
          </div>
        </Frame>

      <Footer />
    </>
  )
}
