import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/machigai/1.png'
import Img2 from '../img/machigai/2.jpeg'
import Img3 from '../img/machigai/3.jpeg'
import Img4 from '../img/machigai/4.jpeg'
import Img5 from '../img/machigai/5.jpeg'
export default function Blog () {
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            間違いさがし
          </H1>
        </Frame>
        <Frame>
          <img src={Img1} className="w-full max-w-3xl m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img2} className="w-full max-w-3xl m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img3} className="w-full max-w-3xl m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img4} className="w-full max-w-3xl m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img5} className="w-full max-w-3xl m-auto" alt=""/>
        </Frame>
      <Footer />
    </>
  )
}
