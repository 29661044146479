import { useNavigate } from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import Banner from './elements/Banner'
import { H1 } from './elements/Heading'
import { Slider1 } from './elements/Slider'
import { Youtube1, Youtube2 } from './elements/Youtube'
import { ScrollTop } from './utils/ScrollTop'
import Swiper1 from '../img/home/swiper1.jpeg'
import Swiper2 from '../img/home/swiper2.jpeg'
import Swiper3 from '../img/home/swiper3.jpeg'
import Swiper4 from '../img/home/swiper4.jpeg'
import Swiper5 from '../img/home/swiper5.png'
import Swiper6 from '../img/home/swiper6.jpeg'
import Banner1 from '../img/home/banner1.jpeg'
import Banner2 from '../img/home/banner2.png'
import Banner3 from '../img/home/banner3.png'
import Banner4 from '../img/home/banner4.jpeg'

import Logo1 from '../img/home/logo1.png'
import Logo2 from '../img/home/logo2.png'
import Logo3 from '../img/home/logo3.png'
import Logo4 from '../img/home/logo4.png'
import Logo5 from '../img/home/logo5.png'
import Logo6 from '../img/home/logo6.png'
import Logo7 from '../img/home/logo7.png'
import Logo8 from '../img/home/logo8.png'

import SakurraCco from '../img/home/sakurra-cco.jpg'

export default function Home() {
  const navigate = useNavigate();
  return (
    <>
      <ScrollTop />
      <Header />
      <Frame>
        <H1>
          てらっこクラブ ホームページへようこそ
        </H1>
      </Frame>
      <Frame>
        <Slider1 slides={[
          Swiper1,
          Swiper2,
          Swiper3,
          Swiper4,
          Swiper5,
          Swiper6,
        ]} />
      </Frame>
      <Frame>
        <div className="grid gap-2 lg:grid-cols-3">
          {[
            { title: "EnglishFunTime", url: null, href: 'https://e-funtime.com/', img: Logo1 },
            { title: "エジソンアカデミー", url: 'programming/robot-programming', href: null, img: Logo2 },
            { title: "自考力キッズ", url: '/programming/jikoryoku-kids', href: null, img: Logo3 },
            { title: "速読解力講座", url: '/sokudoku/sokudokkairyoku', href: null, img: Logo4 },
            { title: "思考力講座", url: '/sokudoku/shikoryoku', href: null, img: Logo5 },
            { title: "速読聴英語講座", url: '/sokudoku/sokudokucho-english', href: null, img: Logo6 },
            { title: "デジそろ", url: '/digi-soro', href: null, img: Logo7 },
            { title: "プログラ道場", url: '/progra-dojo', href: null, img: Logo8 },
          ].map((items, key) => (
            <div key={key}>
              <div className="p-2">
                <div className="cursor p-10">
                  {items.href != null ? (
                    <a href={items.href} target="_blank" rel="noopener noreferrer">
                      <img src={items.img} className="w-full object-cover object-center" />
                    </a>
                  ) : (
                    <img src={items.img} className="w-full object-cover object-center" onClick={() => navigate(items.url)} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Frame>
      <Frame>
        <H1>
          小学校準備講座スタート！
        </H1>
        <Youtube1 urls={[
          { id: "uHtORg1qnr0" }
        ]} height={'500px'} />
      </Frame>
      <Frame>
        <Youtube1 urls={[
          { id: "bLuIf7BqmSs" }
        ]} height={'500px'} />
      </Frame>
      <Frame>
        <Banner datas={[
          { src: SakurraCco, url: null, href: "https://home.tsuku2.jp/storeDetail.php?scd=0000263709" },
          { src: Banner2, url: '/gallery', href: null },
          { src: Banner3, url: '/machigai', href: null },
          { src: Banner4, url: null, href: "https://www.business-plus.net/interview/2010/k5857.html" },
        ]} />
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "zy1y_1t4kUo" },
          { id: "9DiG92ZveN8" },
          { id: "YocjMaFTs3s" },
          { id: "dcMmYyDf1Fk" },
          { id: "9xWCqg3yu6E" },
          { id: "HSxFvr1X0ss" },
        ]} height={'200px'} />
      </Frame>
      <Footer />
    </>
  )
}
