import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/syakaijin/1.png'
import Img2 from '../img/syakaijin/2.png'
import Img3 from '../img/syakaijin/3.png'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { IconContext }    from 'react-icons'
export default function Syakaijin () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            社会人速読
          </H1>
        </Frame>
        <Frame>
          <img src={Img1} className="w-full max-w-xl m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img2} className="w-full m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img3} className="w-full m-auto" alt=""/>
        </Frame>
        <Frame>
          <div className="m-3">
            <div className="flex justify-center">
              <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
                <IoIosArrowDroprightCircle />
                <span className="text-xl ml-1"> お申し込み </span>
              </a>
            </div>
          </div>
        </Frame>
      <Footer />
    </>
  )
}
