import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1, H2 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import { Youtube1 } from './elements/Youtube'
import Img1 from '../img/sokudokucho/1.png'
import Img2 from '../img/sokudokucho/2.png'
import Img3 from '../img/sokudokucho/3.png'

import Img9 from '../img/elementary-english/9.jpeg'
import Img10 from '../img/elementary-english/10.png'
import Img11 from '../img/elementary-english/11.png'
import Img12 from '../img/elementary-english/12.png'
import Img13 from '../img/elementary-english/13.png'

import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { IconContext }    from 'react-icons'
export default function Sokudokucho () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            速読聴英語講座（小中高生）
          </H1>
        </Frame>
        <Frame>
          <img src={Img1} className="w-full max-w-xl m-auto" alt=""/>
        </Frame>
        <Frame>
          <Youtube1 urls={[
            { id: "cMvNN2SYXYE" },
          ]} height={'600px'}/>
        </Frame>
        <Frame>
          <img src={Img2} className="w-full m-auto" alt=""/>
        </Frame>
        <Frame>
          <img src={Img3} className="w-full m-auto" alt=""/>
        </Frame>
        <Frame>
          <H2>
            受講料
          </H2>
          <div className="m-3 leading-loose">
          ・月額制＜１回60分 月４回＞速読聴英語講座：￥19,800
          </div>
          <div className="m-3">
            <div className="flex justify-center">
              <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
                <IoIosArrowDroprightCircle />
                <span className="text-xl ml-1"> お申し込み </span>
              </a>
            </div>
          </div>
        </Frame>

        <Frame>
          <H2>
            早くから英語を「読む・聴く」トレーニングを通して英語に慣れる
          </H2>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="divide-y">
                <div>
                  <div className="flex flex-col sm:flex-row mt-5 mb-5">
                    <div className="sm:w-1/2">
                      <img src={Img9} className="w-full object-cover object-center" alt=""/>
                    </div>
                    <div className="sm:w-1/2 sm:pl-6  border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                      <Youtube1 urls={[
                        {id: "HSxFvr1X0ss"}
                      ]} height={'340px'}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <H2>
            たくさんの語彙を「読む・聴く」英語指導のプロが監修した単語学習
          </H2>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="text-lg">
                <div className="rounded bg-yellow-100 border border-red-500 px-4 py-3">
                小学校の英語教育は2020年度に必修となり、高校卒業まで一貫性を持った授業内容に変わっていきます。<br/>
                学習する単語数も中学校で1,000語以上増加、小学校の間で習得を目指す単語数も600～700語に増えました。<br/>
                <br/>
                　言葉を話したり、書いたりするためには、たくさんの語彙を「読む」「聴く」というインプットを<br/>
                することが必要です。読んで、聴いた量が多ければ多いほど、「話す」「書く」等のアウトプットのとき、<br/>
                さまざまな伝え方ができます。<br/>
                <br/>
                　今回、小学生対象のCourse Ⅰのリニューアルでは、東大や難関大へ多くの生徒を排出する灘中学・高等学校で<br/>
                英語を指導されていた木村達哉先生監修の英単語学習帳を元に新規英文を投入しました。<br/>
                <br/>
                　Course Ⅰのトレーニングを通して、小学生で理解したい600～700語を網羅することが可能です。<br/>
                <br/>
                　また、「New HORIZON」「Elementary」「Here We Go」「CROWN Jr」といった<br/>
                小学校で使われている教科書の学習指導内容や進度を参考にふまえて、単元の構成を見直しました。<br/>
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="divide-y">
                <div>
                  <div className="flex flex-col sm:flex-row mt-5 mb-5">
                    <div className="sm:w-1/2">
                      <img src={Img10} className="w-full object-cover object-center" alt=""/>
                    </div>
                    <div className="sm:w-1/2 sm:pl-6  border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                      <img src={Img11} className="w-full object-cover object-center" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <H2>
            続けることで身につく！成果向上、モチベーションアップの仕組み
          </H2>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="text-lg">
                <div className="rounded bg-yellow-100 border border-red-500 px-4 py-3">
                「速読聴英語講座」では、トレーニングの成果確認の機会として、年8回の「マンスリーチェック」や、<br/>
                年2回の「速読聴英語検定」を受検することで、客観的に実力を把握することができます。<br/>
                <br/>
                　また、楽しみながらトレーニングを続けられる工夫として、世界旅行をベースとしたゲーミフィケーション要素を<br/>
                搭載しています。トレーニングでためた仮想通貨（ページ）で、訪れた国のアイテムをお土産として買うことができ、<br/>
                マイルームへコレクションしていくことができます。<br/>
                <br/>
                　また、訪れた国の情報を知ることができるので異文化学習にもつながります。
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <img src={Img12} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <H2>
            受講生の声
          </H2>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="text-lg">
                <div className="rounded bg-yellow-100 border border-red-500 px-4 py-3">
                  <div className="text-md inline-flex items-center rounded px-3 py-3 cursor">
                    <IconContext.Provider value={{ color: 'orange'}}>
                      <AiFillCheckCircle />
                      小３から受講し楽しく続けられた！中学入試で英語試験免除
                    </IconContext.Provider>
                  </div>
                  <div className="text-sm inline-flex items-center rounded px-3 py-3 cursor">
                    英検準2級合格　S.Mさん（中学1年生）<br/>
                    <br/>
                  速読聴英語を始めてからリスニングが聴きやすくなったり、「あ、ここ知ってる」と思うことが増えました。<br/>
                  受講後にもらえるポイントや、ワールドマップ旅するところ、ボキャブラリーですぐに丸がつけられるところが<br/>
                  オススメです。レベルやコインがたまったりゲーム感覚でできるのでとても楽しいです。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="text-lg">
                <div className="rounded bg-yellow-100 border border-red-500 px-4 py-3">
                  <div className="text-md inline-flex items-center rounded px-3 py-3 cursor">
                    <IconContext.Provider value={{ color: 'orange'}}>
                      <AiFillCheckCircle />
                      小５から受講１年間で英検２級合格！中学のテストでも100点
                    </IconContext.Provider>
                  </div>
                  <div className="text-sm inline-flex items-center rounded px-3 py-3 cursor">
                    英検2級合格　S.Aさん（中学1年生）<br/>
                    <br/>
                  小学生の頃は単調で難しいと思っていたけれど、英検に合格できて自信がつきました。<br/>
                  そのおかげで、中学受験では特待生として奨学金をもらえましたし、中学1年生1学期の中間テストでは<br/>
                  100点を取ることができました。これからも受講を続けて英語の力を伸ばしていきたいです。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <img src={Img13} className=" w-full object-cover object-center" alt=""/>
        </Frame>
        <Frame>
          <div className="m-3">
            <div className="flex justify-center">
              <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
                <IoIosArrowDroprightCircle />
                <span className="text-xl ml-1"> 体験申し込み </span>
              </a>
            </div>
          </div>
        </Frame>

      <Footer />
    </>
  )
}
