import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1, H2 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/eisai/1.jpeg'
import { Slider1 } from './elements/Slider'
import Swiper1 from '../img/eisai/swiper1.jpeg'
import Swiper2 from '../img/eisai/swiper2.jpeg'
import Swiper3 from '../img/eisai/swiper3.jpeg'
import Swiper4 from '../img/eisai/swiper4.jpeg'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { IconContext }    from 'react-icons'
export default function Eisai () {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            After School 英才コース
          </H1>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="divide-y">
                <div className="pt-3 pb-3">
                  <h1 className="text-xl font-medium text-gray-900 text-left">
                    国語・算数・速読＆思考力 トレーニング
                  </h1>
                </div>
                <div>
                  <div className="flex flex-col sm:flex-row mt-5 mb-5">
                    <div className="sm:w-1/2">
                      <ul>
                        <li className="m-3 text-md">
                          ピアノやバイオリンなど、多くの習い事は３歳から・・・
                        </li>
                        <li className="m-3 text-md">
                          なのに「学び」はなぜ６歳から？
                        </li>
                        <li className="m-3 text-md">
                          「英才コース」はノングレードスパイラルで進むカリキュラムです
                        </li>
                        <li className="m-3 text-md">
                          中学受験や高校・大学受験・・・
                        </li>
                        <li className="m-3 text-md">
                          そして、その先の未来に向けて子どもたちに「余裕」を作ります。
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-1/2 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                      <img src={Img1} className=" w-full object-cover object-center" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="text-lg">
                <div className="rounded bg-yellow-100 border border-red-500 px-4 py-3">
                　After School 英才コース　＜年中～小学６年生 対象＞<br/>
                ・時間：13:00～19:00<br/>
                ・曜日：火曜・木曜・土曜 から選びます<br/>
                ・回数：週１・２・３回から選びます<br/>
                ・国・算・速読＆思考力90分＋学校の宿題30分＋After School
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="divide-y">
                <div className="pt-3 pb-3">
                  <h1 className="text-xl font-medium text-gray-900 text-left">
                    効果的な学習は「ポジティブ感情」と「学ぶ喜び」
                  </h1>
                </div>
                <div>
                  <div className="flex flex-col sm:flex-row mt-5 mb-5">
                    <div className="sm:w-1/2">
                      <ul className="flex flex-col">
                        <li className="text-md inline-flex items-center rounded px-3 py-3 cursor">
                          <IconContext.Provider value={{ color: 'orange'}}>
                            <AiFillCheckCircle />
                            たくさんの笑顔
                          </IconContext.Provider>
                        </li>
                        <li className="text-md inline-flex items-center rounded px-3 py-3 cursor">
                          <IconContext.Provider value={{ color: 'orange'}}>
                            <AiFillCheckCircle />
                            たくさん間違いましょう
                          </IconContext.Provider>
                        </li>
                        <li className="text-md inline-flex items-center rounded px-3 py-3 cursor">
                          <IconContext.Provider value={{ color: 'orange'}}>
                            <AiFillCheckCircle />
                            たくさん話をしましょう
                          </IconContext.Provider>
                        </li>
                        <li className="text-md inline-flex items-center rounded px-3 py-3 cursor">
                          <IconContext.Provider value={{ color: 'orange'}}>
                            <AiFillCheckCircle />
                            信じることと待つことを大切にします
                          </IconContext.Provider>
                        </li>
                        <li className="text-md inline-flex items-center rounded px-3 py-3 cursor">
                          <IconContext.Provider value={{ color: 'orange'}}>
                            <AiFillCheckCircle />
                            聴くことを大切にします
                          </IconContext.Provider>
                        </li>
                      </ul>
                    </div>
                    <div className="sm:w-1/2 sm:pl-6 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                      <img src={Swiper1} className=" w-full object-cover object-center" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <div className="flex flex-wrap">
            <div className="w-screen">
              <div className="text-lg">
                <div className="rounded bg-yellow-100 border border-red-500 px-4 py-3">
                ・こどもは話したがり屋です<br/>
                ・それは「賢くなりたい」という本能です。<br/>
                ・聞く側にいるよりも話をするほうが頭を使います、脳は動きます。
                </div>
              </div>
            </div>
          </div>
        </Frame>
        <Frame>
          <H2>
            受講料
          </H2>
          <div className="m-3 leading-loose">
            After School 英才コース：月額制<br/>
            ・【4回/月】週１回：￥27,500<br/>
            ・【8回/月】週２回：￥49,500<br/>
            ・【12回/月】週３回：￥66,000
          </div>
          <div className="m-3">
            <div className="flex justify-center">
              <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={()=>navigate(`/trial-contact`)}>
                <IoIosArrowDroprightCircle />
                <span className="text-xl ml-1"> お申し込み </span>
              </a>
            </div>
          </div>
        </Frame>
      <Footer />
    </>
  )
}
