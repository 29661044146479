import { useNavigate }  from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { H1 } from './elements/Heading'
import { ScrollTop } from './utils/ScrollTop'
import Img1 from '../img/programming/robot.png'
import Img2 from '../img/programming/jikoryoku.gif'
export default function Programming () {
  const navigate = useNavigate();
  return (
    <>
      <ScrollTop />
      <Header />
        <Frame>
          <H1>
            「論理的思考力・問題解決能力」の育成
          </H1>
        </Frame>
        <Frame>
          <div className="grid gap-2 sm:grid-cols-2">
            <div>
              <div className="p-2">
                <div className="rounded-xl p-10">
                  <img src={Img1} className="mb-3 cursor" alt="" onClick={()=>navigate(`/programming/robot-programming`)}/>
                </div>
              </div>
            </div>
            <div>
              <div className="p-2">
                <div className="rounded-xl p-10">
                  <img src={Img2} className="mb-3 cursor" alt="" onClick={()=>navigate(`/programming/jikoryoku-kids`)}/>
                </div>
              </div>
            </div>
          </div>
        </Frame>
      <Footer />
    </>
  )
}
