import Logo from '../../img/logo_small.png'
export const H1 = (props: any) => {
  return (
    <div>
      <div className="inline-flex items-center space-x-2">
        <div>
          <img src={Logo} alt="アイコン" className="h-10 w-10 cursor" />
        </div>
        <div className="text-2xl font-bold">
          {props.children}
        </div>
      </div>
    </div>
  )
}
export const H2 = (props: any) => {
  return (
    <div className="divide-y divide-dashed divide-red-500 pt-3 pb-3">
      <div className="text-lg text-gray-600">
        {props.children}
      </div>
      <div>

      </div>
    </div>
  )
}
export const H3 = (props: any) => {
  return (
    <div>
      <div className="inline-flex items-center space-x-2">
        <div>
          <div className="text-lg">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
