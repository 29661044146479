import { Swiper, SwiperSlide }    from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export const Slider1 = ({slides}: {slides: any}) => {
  return (
    <Swiper modules={[ Navigation, Pagination ]} navigation pagination={{ clickable: true }} >
      {slides.map((slide: any)=>(
        <SwiperSlide>
          <img src={slide} className="w-full" alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export const Slider2 = ({arr}: {arr: any}) => {
  return (
    <div className="flex flex-col sm:flex-row">
      {arr.map((slides: any, key: any) => (
        <div className="sm:w-1/2">
          <div key={key}>
            <Swiper modules={[ Navigation, Pagination ]} navigation pagination={{ clickable: true }} >
              {slides.map((slide: any)=>(
                <SwiperSlide>
                  <img src={slide} className="w-full" alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ))}
    </div>
  )
}
