import { useNavigate } from "react-router-dom";
import Header from './elements/Header'
import Footer from './elements/Footer'
import Frame from './elements/Frame'
import { Slider1, Slider2 } from './elements/Slider'
import { Youtube1, Youtube2 } from './elements/Youtube'
import { ScrollTop } from './utils/ScrollTop'
import { H1, H2 } from './elements/Heading'
import { IconContext } from 'react-icons'
import { MdDeleteOutline } from "react-icons/md"
import { BsSignpost2 } from "react-icons/bs"
import { TbAddressBook } from "react-icons/tb"
import { AiOutlineHome } from "react-icons/ai"
import { AiOutlineHeart } from "react-icons/ai"
import { MdOutlineCategory } from "react-icons/md"
import { BsArrowRightCircle } from "react-icons/bs"
import { TbBuildingWarehouse } from "react-icons/tb"
import Img1 from '../img/progra-dojo/1.jpeg'
import Img2 from '../img/progra-dojo/2.jpeg'
import PrograBook1 from '../img/progra-dojo/progra-book/1.jpeg'
import PrograBook2 from '../img/progra-dojo/progra-book/2.jpeg'
import PrograBook3 from '../img/progra-dojo/progra-book/3.jpeg'
import PrograBook4 from '../img/progra-dojo/progra-book/4.jpeg'
import PrograBook5 from '../img/progra-dojo/progra-book/5.jpeg'
import PrograBook6 from '../img/progra-dojo/progra-book/6.jpeg'
import PrograBook7 from '../img/progra-dojo/progra-book/7.jpeg'
import PrograBook8 from '../img/progra-dojo/progra-book/8.jpeg'
import PrograBook9 from '../img/progra-dojo/progra-book/9.jpeg'
import PrograBook10 from '../img/progra-dojo/progra-book/10.jpeg'
import PrograDojo1 from '../img/progra-dojo/progra-dojo/1.jpeg'
import PrograDojo2 from '../img/progra-dojo/progra-dojo/2.jpeg'
import PrograDojo3 from '../img/progra-dojo/progra-dojo/3.jpeg'
import PrograDojo4 from '../img/progra-dojo/progra-dojo/4.jpeg'
import PrograDojo5 from '../img/progra-dojo/progra-dojo/5.jpeg'
import PrograDojo6 from '../img/progra-dojo/progra-dojo/6.jpeg'
import PrograDojo7 from '../img/progra-dojo/progra-dojo/7.jpeg'
import PrograDojo8 from '../img/progra-dojo/progra-dojo/8.jpeg'
import PrograDojo9 from '../img/progra-dojo/progra-dojo/9.jpeg'
import PrograDojo10 from '../img/progra-dojo/progra-dojo/10.jpeg'
import CompanyInfo1 from '../img/progra-dojo/_company-info/_1.png'
import CompanyInfo2 from '../img/progra-dojo/_company-info/_2.png'
import CompanyInfo3 from '../img/progra-dojo/_company-info/_3.png'
import CompanyInfo4 from '../img/progra-dojo/_company-info/_4.png'
import CompanyInfo5 from '../img/progra-dojo/_company-info/_5.png'
import CompanyInfo6 from '../img/progra-dojo/_company-info/_6.png'
import CompanyInfo7 from '../img/progra-dojo/_company-info/_7.png'
import CompanyInfo8 from '../img/progra-dojo/_company-info/_8.png'
import CompanyInfo9 from '../img/progra-dojo/_company-info/_9.png'
import CompanyInfo10 from '../img/progra-dojo/_company-info/_10.png'
import CompanyInfo11 from '../img/progra-dojo/_company-info/_11.png'
import CompanyInfo12 from '../img/progra-dojo/_company-info/_12.png'
import CompanyInfo13 from '../img/progra-dojo/_company-info/_13.png'
import CompanyInfo14 from '../img/progra-dojo/_company-info/_14.png'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
export default function PrograDojo() {
  const navigate = useNavigate()
  return (
    <>
      <ScrollTop />
      <Header />
      <Frame>
        <H1> プログラ道場 </H1>
      </Frame>
      <Frame>
        <div className="grid gap-2 sm:grid-cols-2">
          {[
            Img1,
            Img2
          ].map((img, key) => (
            <div key={key}>
              <img src={img} width="100%" alt="アイコン" className="cursor" />
            </div>
          ))}
        </div>
      </Frame>
      <Frame>
        <Youtube1 urls={[
          { id: "zV6CJ6i6m9k" }
        ]} height={'500px'} />
      </Frame>
      <Frame>
        <H2>
          「プログラ道場」学びの風景
        </H2>
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "Lt0OTTgh0uk" },
          { id: "M1WYvjwH08s" }
        ]} height={'300px'} />
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "1yqUiU2B_Ho" },
          { id: "3q0itbdwxNw" }
        ]} height={'300px'} />
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "h87YplQX5n4" },
          { id: "xSlrb1HMMkw" }
        ]} height={'300px'} />
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "F3Z1CZ40jP4" },
          { id: "KlWGGuY-Sw8" }
        ]} height={'300px'} />
      </Frame>
      <Frame>
        <H2>
          ゲーム制作プログラミング
        </H2>
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "es-2s4qG4so" },
          { id: "pl-_CzjX41M-Sw8" }
        ]} height={'300px'} />
      </Frame>
      <Frame>
        <Youtube2 urls={[
          { id: "2iphG1B1Wog" },
          { id: "UvCv_cAOb60" }
        ]} height={'300px'} />
      </Frame>
      <Frame>
        <Slider2 arr={[
          [
            PrograDojo1,
            PrograDojo2,
            PrograDojo3,
            PrograDojo4,
            PrograDojo5,
            PrograDojo6,
            PrograDojo7,
            PrograDojo8,
            PrograDojo9,
            PrograDojo10,
          ],
          [
            PrograBook1,
            PrograBook2,
            PrograBook3,
            PrograBook4,
            PrograBook5,
            PrograBook6,
            PrograBook7,
            PrograBook8,
            PrograBook9,
            PrograBook10,
          ],
        ]} />
      </Frame>
      <Frame>
        <Slider1 slides={[
          CompanyInfo1,
          CompanyInfo2,
          CompanyInfo3,
          CompanyInfo4,
          CompanyInfo5,
          CompanyInfo6,
          CompanyInfo7,
          CompanyInfo8,
          CompanyInfo9,
          CompanyInfo10,
          CompanyInfo11,
          CompanyInfo12,
          CompanyInfo13,
          CompanyInfo14,
        ]} />
      </Frame>
      <Frame>
        <div className="m-3">
          <div className="flex justify-center">
            <a className="inline-flex items-center rounded bg-yellow-100 border border-red-500 px-4 py-3 text-red-500 cursor" onClick={() => navigate(`/trial-contact`)}>
              <IoIosArrowDroprightCircle />
              <span className="text-xl ml-1"> 体験申し込み </span>
            </a>
          </div>
        </div>
      </Frame>
      <Footer />
    </>
  )
}
